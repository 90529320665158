<template>
  <div class="page-table only-y mainDiv mb-30">
    <div class="card-base baslikBg animated fadeInUp">
      {{ $t("src.views.apps.tatil.liste.title") }}
      <div class="tanimlamaButon">
        <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")' :open-delay="500" placement="top"
                    v-if="yetkiTuru == 1 || (yetkiTuru == 2 && (yetkiListesi.includes('YK-resmiTatilC100')))">
          <el-button v-on:click="routeMusteri('Tatil Tanımla')" size="mini" type="text"
                     icon="el-icon-plus"></el-button>
        </el-tooltip>
        <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
          <el-button v-on:click="refreshPage()" style="margin-left: 15px !important" type="text"
                     icon="el-icon-refresh"></el-button>
        </el-tooltip>
      </div>
    </div>
    <div class="page-header card-base card-shadow--medium animated fadeInUp" v-loading="loading || stateLoading"
         :element-loading-text='$t("src.views.apps.tatil.liste.loading")' element-loading-spinner="el-icon-loading"
         element-loading-background="rgba(255, 255, 255, 1)">
      <el-row class="mt-0 mb-10">
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <div>
            <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9"
                            text-color="gray" :disabled="searchLoading">
            </el-radio-group>
          </div>
        </el-col>
        <el-col :lg="12" :md="12" :sm="24" :xs="24">
          <el-col :lg="24" :md="24" :sm="24" :xs="24">
            <ul class="islemler" v-if="isDesktop">
              <li class="duzenle"
                  v-if="yetkiTuru == 1 || (yetkiTuru == 2 && (yetkiListesi.includes('YK-resmiTatilR100') || yetkiListesi.includes('YK-resmiTatilR200') || (yetkiListesi.includes('YK-resmiTatilU100'))))">
                <i class="mdi mdi-circle-edit-outline"></i>
                {{ $t("src.views.apps.tatil.liste.table.editTatil") }}
              </li>
            </ul>
            <ul class="islemler" v-else>
              <li class="duzenle"
                  v-if="yetkiTuru == 1 || (yetkiTuru == 2 && (yetkiListesi.includes('YK-resmiTatilR100') || yetkiListesi.includes('YK-resmiTatilR200') || (yetkiListesi.includes('YK-resmiTatilU100'))))">
                <i class="mdi mdi-circle-edit-outline"></i>
                {{ $t("src.views.apps.tatil.liste.table.editTatil") }}
              </li>
            </ul>
          </el-col>
        </el-col>
      </el-row>
      <el-table v-loading="searchLoading" border stripe :data="tatilList" style="width: 100%"
                :element-loading-text='"Tatil Aranıyor. Lütfen Bekleyin."' element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(255, 255, 255, 1)">
        <el-table-column>
          <template slot="header">
          </template>
          <el-table-column :label='$t("src.views.apps.genel.sira")' type="index" :index="indexMethod" width="70">
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="tatilAra"
                      :placeholder='$t("src.views.apps.tatil.liste.search.ozelGun")' v-model="ozelGunAra"
                      v-debounce:500ms="tatilAra" size="mini"></el-input>
          </template>
          <el-table-column prop="ozelGun" label="Özel Gün" width="400">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.sebep }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="tatilAra"
                      :placeholder='$t("src.views.apps.tatil.liste.search.turAra")' v-model="turAra"
                      v-debounce:500ms="tatilAra" size="mini"></el-input>
          </template>
          <el-table-column prop="tamGun" :label='$t("src.views.apps.tatil.liste.table.tatilTuru")'
                           :width="isDesktop ? 'auto' : '300'">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.tamGun == 1 ? "Tam Gün" : "Yarım Gün" }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-date-picker clearable @change="tatilAra"
                            :placeholder='$t("src.views.apps.tatil.liste.search.baslangicAra")' v-model="baslangicAra"
                            format="dd.MM.yyyy" value-format="yyyy-MM-dd" size="mini" class="select-wide"
                            type="date"></el-date-picker>
          </template>
          <el-table-column prop="baslangicTarihi" :label='$t("src.views.apps.tatil.liste.table.tatilBaslangic")'
                           width="300">

            <template slot-scope="props">
              <div class="new-line-text">
                {{ (props.row.baslamaTarihi) }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-date-picker clearable @change="tatilAra"
                            :placeholder='$t("src.views.apps.tatil.liste.search.bitisAra")' v-model="bitisAra"
                            format="dd.MM.yyyy" value-format="yyyy-MM-dd" size="mini" class="select-wide"
                            type="date"></el-date-picker>
          </template>
          <el-table-column prop="bitisTarihi" :label='$t("src.views.apps.tatil.liste.table.tatilBitis")'
                           width="200">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.bitisTarihi }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column>
          <template slot="header" slot-scope="props">
            <el-input clearable v-on:clear="tatilAra"
                      :placeholder='$t("src.views.apps.tatil.liste.search.toplamGun")' v-model="toplamGunAra"
                      v-debounce:500ms="tatilAra" size="mini"></el-input>
          </template>
          <el-table-column prop="eposta" :label='$t("src.views.apps.tatil.liste.table.toplamGun")' width="280">
            <template slot-scope="props">
              <div class="new-line-text">
                {{ props.row.gunMiktari }}
              </div>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right"
                         v-if="yetkiTuru == 1 || (yetkiTuru == 2 && (yetkiListesi.includes('YK-resmiTatilR100') || (yetkiListesi.includes('YK-resmiTatilU100'))))">
          <el-table-column :label='$t("src.views.apps.genel.islem")' align="right"
                           :width="isDesktop ? '100' : '100'">
            <template slot-scope="scope">

              <el-tooltip :content='$t("src.views.apps.tatil.liste.table.editTatil")' :open-delay="500"
                          placement="bottom">
                <el-button @click="updatePage(scope.row)" type="text" class="islem-button"
                           style="color: #f7ba2a; margin-left: 0px !important;"
                           icon="mdi mdi-circle-edit-outline mdi-24px"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
      <div v-if="tatilList.length == []"
           style="width: 100%; height: 50px !important; display: flex; justify-content: center; align-items: center">
        {{ $t("src.views.apps.messages.noData") }}
      </div>
      <el-pagination background layout="sizes,prev, pager, next, jumper, total" :total="total"
                     @size-change="handleSizeChange" @current-change="pageChange" :page-sizes="[10, 20, 50, 100]"
                     :current-page.sync="page" :next-click.sync="page" :prev-click.sync="page" @next-click="pageChange"
                     @prev-click="pageChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import tatilService from '../../../WSProvider/TatilService'
import EventBus from '@/components/event-bus'
import notification from '../../../notification'
import functions from '../../../functions'

var moment = require('moment');

export default {
  name: "TatilListesi",
  data() {
    return {
      isDesktop: false,
      loading: false,
      stateLoading: false,
      searchLoading: false,
      //editDialogLoading: false,
      editDialog: false,
      editDialogData: {},
      musteriList: [],
      tatilList: [],
      yetkiListesi: [],
      yetkiTuru: "",
      radio: '1',
      musteriForm: {
        musteriAdi: "",
        eposta: "",
        firmaAdi: "",
        unvan: "",
        telefon: ""
      },
      rulesMusteri: {},
      sizes: 10,
      selectIcon: '1',
      page: 0,
      postPage: 0,
      total: 4,
      ozelGunAra: "",
      turAra: "",
      baslangicAra: "",
      bitisAra: "",
      toplamGunAra: "",
    }
  },
  created() {
    this.checkDevice();
    if (localStorage.getItem("userDataBGSurec")) {
      this.yetkiTuru = this.$store.getters.getYetkiTuru;
      this.yetkiListesi = this.$store.getters.getYetkiListesi;
      //console.log(this.yetkiListesi)

    }
  },
  mounted() {
    const self = this
    this.getTatilListesi();
    window.addEventListener('resize', this.checkDevice);
    EventBus.$on("tatilList", function (bool) {
      if (bool) {
        self.getTatilListesi();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkDevice);
  },
  methods: {
    checkDevice() {
      this.isDesktop = window.innerWidth >= 768;
    },
    updatePage(data) {
      this.$store.commit('changeTatilUpdateData', data.resmiTatilID)
      functions.routeSayfa("Tatil Güncelle", this)
    },
    tatilAra() {
      this.postPage = 0;
      this.page = 1
      this.dateVisible = false;
      //siparisNo, adSoyad, telefon, country, city, state, baslangicTarihi, bitisTarihi, durum, baslangic, limit,
      if (this.ozelGunAra.length > 0 || this.turAra.length > 0 || this.baslangicAra || this.bitisAra || this.toplamGunAra.length > 0) {
        try {
          this.searchLoading = true;
          //kullaniciAdi, adSoyad, mail, telefon, durum, baslangic, limit)
          tatilService.resmiTatilFiltrele(this.baslangicAra, this.bitisAra, this.ozelGunAra, this.turAra, this.toplamGunAra, this.postPage, this.sizes).then(response => {
            if (response.status == 200) {
              this.tatilList = response.data;
              this.total = response.count
            }
            localStorage.setItem("userDataBGSurec", response.token);
            this.searchLoading = false;
          }).catch(err => {
            if (err.responseJSON) {
              let error = err.responseJSON
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.tatilList = []
              }
            } else {
              //notification.Status(503, this)
            }
            this.searchLoading = false;
          })
        } catch (e) {
          // notification.Status("danger", this, "An error occurred during the customer search process.")
          this.searchLoading = false
        }
      } else {
        this.getTatilListesi();
      }
    },
    refreshPage() {
      this.page = 1;
      this.postPage = 0;
      this.loading = false;
      this.stateLoading = false;

      this.getTatilListesi();
    },
    vazgec(formName) {
      this.$refs[formName].resetFields();
      this.editDialog = false;
      this.loading = false;
      this.stateLoading = false;
    },
    getTatilListesi() {
      try {
        this.loading = true;
        var currentYear = getCurrentYear();
        tatilService.resmiTatilListele(this.postPage, this.sizes, currentYear).then((response) => {
          localStorage.setItem("userDataBGSurec", response.token)
          response.data.forEach(function (e) {
            //e.baslamaTarihi = moment(e.baslamaTarihi).format('DD-MM-YYYY');
            e.baslamaTarihi = moment(e.baslamaTarihi).format('DD.MM.YYYY');
            e.bitisTarihi = moment(e.bitisTarihi).format('DD.MM.YYYY');
          })
          if (response.status == 200) {
            this.total = parseInt(response.data.musteriSayisi);
            this.tatilList = response.data;
            this.$store.commit('setTatilList', response.data)
            this.total = parseInt(response.count);
          }
          this.loading = false;
        }).catch(err => {
          if (err.responseJSON) {
            let error = err.responseJSON
            if (error.status == 401) {
              notification.Status(150, this);
            } else if (error.status == 404) {
              this.tatilList = [];
            } else notification.Status("errorMsg", this, error.errMsg)
          } else {
            //notification.Status(503, this)
          }
          this.loading = false;
        })
      } catch (e) {
        this.loading = false
      }
    },
    routeMusteri(name, scope) {
      if (scope) {
        this.$store.commit('changeTatilUpdateData', scope);
      }
      functions.routeSayfa(name, this);
    },
    indexMethod(index) {
      return ((this.page - 1) * 10) + index + 1;
    },
    handleSizeChange(val) {
      this.sizes = val;
      this.getTatilListesi();
    },
    handleChange(event) {
      this.selectIcon = event;
      this.page = 1;
      this.postPage = 0;
      this.getTatilListesi();
      document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
      this.ozelGunAra = ""
      this.turAra = ""
      this.baslangicAra = ""
      this.bitisAra = ""
      this.toplamGunAra = ""
    },
    pageChange(val) {
      this.postPage = (val - 1) * this.sizes;
      this.getTatilListesi();
    },
  }
}

function getCurrentYear() {
  var date = new Date();
  var year = date.getFullYear();
  return year;
}

</script>

<style lang="scss">
.el-table .info-row {
  background: rgba(125, 199, 225, 0.45);
}

.el-table .error-row {
  background: rgba(249, 0, 26, 0.08);
}

.el-table .success-row {
  background: rgba(3, 249, 0, 0.08);
}

.el-table .warning-row {
  background: rgba(245, 249, 0, 0.08);
}
</style>
<style lang="scss" scoped>
.refresh {
  color: #fff;
  float: right;
  padding: 0 !important;
  transition: all 0.5s;

}

.refresh:hover {
  transform: rotate(360deg);
}

.el-pagination {
  margin-top: 20px;
  float: right;
}

.el-radio-button--mini .el-radio-button__inner label {
  position: relative;
  bottom: 5px;
}

.kaldirilmis {
  background: #ec205f;
  color: white;
  font-weight: bold;
  font-size: 16px;
  padding: 10px
}
</style>
<style lang="scss">
.slide-fade-enter-active {
  transition: all .3s ease;
}


@media (min-width: 768px) {
  .el-radio-button--mini .el-radio-button__inner {
    padding: 0 10px !important;
    font-size: 11px;
    border-radius: 0;
  }
}


.slide-fade-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}
</style>